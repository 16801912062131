import {AfterViewInit, Component, ElementRef, Input, ViewChild} from '@angular/core';
import {LinkModel} from '../../../models';
import {SnackbarService} from '../../../services';


@Component({
    selector: 'app-link-list',
    templateUrl: './link-list.component.html',
    styleUrls: ['./link-list.component.scss']
})
export class LinkListComponent implements AfterViewInit {
    @Input() links: LinkModel[] = [];
    @ViewChild('linkList') linkList: ElementRef<HTMLUListElement>;
    isScrollable = false;

    constructor(private snackbarService: SnackbarService) {
    }

    ngAfterViewInit(): void {
        setTimeout(() => {
            if (this.linkList) {
                this.isScrollable = this.linkList.nativeElement.scrollWidth > this.linkList.nativeElement.clientWidth;
                this.linkList.nativeElement.addEventListener('scroll', () => {
                    this.isScrollable = this.linkList.nativeElement.scrollWidth > this.linkList.nativeElement.clientWidth;
                }, {passive: true});
            }
        }, 3000);
    }

    debounce(func: Function, wait: number) {
        let timeout: any;
        return (...args: any) => {
            if (timeout) clearTimeout(timeout);
            timeout = setTimeout(() => func(...args), wait);
        };
    }

    scrollLeft = this.debounce(() => {
        if (this.linkList) {
            const element = this.linkList.nativeElement;
            const scrollDistance = 410;
            if (element.scrollLeft > 0) {
                element.scrollBy({
                    left: -scrollDistance,
                    behavior: 'smooth'
                });
            }
        }
    }, 150);

    scrollRight = this.debounce(() => {
        if (this.linkList) {
            const element = this.linkList.nativeElement;
            const maxScrollLeft = element.scrollWidth - element.clientWidth;
            const scrollDistance = 410;
            if (element.scrollLeft < maxScrollLeft) {
                element.scrollBy({
                    left: scrollDistance,
                    behavior: 'smooth'
                });
            }
        }
    }, 150);

    getThumbnail(url: string, thumbnail?: string): string {
        if (thumbnail) {
            return thumbnail;
        } else if (this.isYouTubeLink(url)) {
            return `https://img.youtube.com/vi/${this.extractVideoId(url)}/0.jpg`;
        }
        return '';
    }

    isYouTubeLink(url: string): boolean {
        return /(?:https?:\/\/)?(?:www\.)?(?:youtube\.com\/(?:[^\/\n\s]+\/\S+\/|(?:v|e(?:mbed)?)\/|\S*?[?&]v=)|youtu\.be\/)/.test(url);
    }

    extractVideoId(url: string): string {
        const videoIdMatch = url.match(/(?:https?:\/\/)?(?:www\.)?(?:youtube\.com\/(?:[^\/\n\s]+\/\S+\/|(?:v|e(?:mbed)?)\/|\S*?[?&]v=)|youtu\.be\/)([a-zA-Z0-9_-]{11})/);
        return videoIdMatch ? videoIdMatch[1] : '';
    }

    copyToClipboard(url: string): void {
        const el = document.createElement('textarea');
        el.value = url;
        document.body.appendChild(el);
        el.select();
        document.execCommand('copy');
        document.body.removeChild(el);
        this.snackbarService.showSnackbar('Link copied to clipboard');
    }
}
