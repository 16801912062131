<div class="member-links" *ngIf="links && links.length > 0" [ngClass]="{ 'has-scroll-controls': isScrollable }">
    <div class="scroll-controls" *ngIf="isScrollable">
        <div class="scroll-left" (click)="scrollLeft()">
            <i class="material-icons">chevron_left</i>
        </div>
        <div class="scroll-right" (click)="scrollRight()">
            <i class="material-icons">chevron_right</i>
        </div>
    </div>
    <ul class="link-list" #linkList>
        <li *ngFor="let link of links; index as i" class="link-item">
            <div class="link-card">
                <div class="link-content">
                    <div (click)="$event.stopPropagation()">
                        <mat-slide-toggle class="slide-toggle"
                                          (click)="$event.preventDefault()"
                                          [checked]="link.active"
                                          (change)="toggleLinkActive(link)"
                                          disableRipple="true">
                            <h5>Link</h5>
                        </mat-slide-toggle>
                    </div>
                    <div class="options-container">
                        <button class="options-button" (click)="toggleOptions(i, $event)">
                            <mat-icon>more_vert</mat-icon>
                        </button>
                        <div class="options-dropdown" [class.show]="openedOptionIndex === i">
                            <button class="option" (click)="$event.stopPropagation(); openEditDialog(link, i)">
                                Edit
                            </button>
                            <button class="option" (click)="$event.stopPropagation(); deleteLink(link, $event)">
                                Delete
                            </button>
                        </div>
                    </div>
                    <div class="link-title"><span>Title:</span> {{ link.title }}</div>
                    <div>
                        <span class="link-url">
                            <span class="link-url-title">URL:</span>
                            <a [href]="link.url" target="_blank">
                                {{ link.url }}
                            </a>
                            <mat-icon class="link-icon">open_in_new</mat-icon>
                        </span>
                    </div>
                </div>
            </div>
        </li>
    </ul>
</div>
